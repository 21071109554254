<template>
  <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
  >
    <path
        d="M20.3203 17L33.0156 4.40234C34.0573 3.29557 34.0573 2.15625 33.0156 0.984375C31.8438 -0.0572917 30.7044 -0.0572917 29.5977 0.984375L17 13.6797L4.40234 0.984375C3.23047 -0.0572917 2.09115 -0.0572917 0.984375 0.984375C-0.0572917 2.15625 -0.0572917 3.29557 0.984375 4.40234L13.6797 17L0.984375 29.5977C-0.0572917 30.7044 -0.0572917 31.8438 0.984375 33.0156C1.57031 33.4714 2.15625 33.6992 2.74219 33.6992C3.26302 33.6992 3.81641 33.4714 4.40234 33.0156L17 20.3203L29.5977 33.0156C30.1836 33.4714 30.737 33.6992 31.2578 33.6992C31.8438 33.6992 32.4297 33.4714 33.0156 33.0156C34.0573 31.8438 34.0573 30.7044 33.0156 29.5977L20.3203 17Z"
        :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "Close",
  props: {
    color:{
      type: String,
      default: "#999187"
    }
  }
};
</script>
