import Vue from 'vue'
import VueRouter from 'vue-router'
import Preview from "@/views/Preview";
import PageNotFound from "@/views/PageNotFound";

Vue.use(VueRouter)

const routes = [
  {
    path: '/:magazineId/',
    name: 'Preview',
    component: Preview
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
