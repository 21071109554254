const state = {
    settings: [],
    topbarActive: true
};

const getters = {
    getSettings: (state) => {
        return state.settings;
    },
    getTopbarActive: (state) => {
        return state.topbarActive;
    }
};

const mutations = {
    SET_SETTINGS(state, settings) {
        state.settings = settings;
    },
    SET_TOPBARACTIVE(state, bool){
        state.topbarActive = bool;
    }
};

export default {
    state,
    getters,
    mutations,
};
