<template>
  <svg
      width="127"
      height="128"
      viewBox="0 0 127 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
  >
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.9767 70.9449L36.4727 57.4488C38.3361 55.5854 41.3573 55.5854 43.2207 57.4488C44.951 59.1791 45.0746 61.9077 43.5915 63.7807L43.2207 64.1968L29.7247 77.6929C24.1345 83.2831 24.1345 92.3466 29.7247 97.9369C35.1153 103.327 43.7355 103.52 49.3568 98.5145L49.9687 97.9369L63.4647 84.4409C65.3281 82.5775 68.3493 82.5775 70.2127 84.4409C71.943 86.1712 72.0666 88.8998 70.5835 90.7727L70.2127 91.1889L56.7167 104.685C47.3997 114.002 32.2937 114.002 22.9767 104.685C13.9048 95.613 13.6661 81.0529 22.2605 71.6922L22.9767 70.9449ZM70.2126 23.709C79.5296 14.3919 94.6355 14.3919 103.953 23.709C113.27 33.026 113.27 48.132 103.953 57.449L90.4566 70.945C88.5932 72.8084 85.572 72.8084 83.7086 70.945C81.8452 69.0816 81.8452 66.0604 83.7086 64.197L97.2046 50.701C102.795 45.1108 102.795 36.0472 97.2046 30.457C91.6143 24.8668 82.5508 24.8668 76.9606 30.457L63.4645 43.953C61.6011 45.8164 58.58 45.8164 56.7165 43.953C54.8531 42.0896 54.8531 39.0684 56.7165 37.205L70.2126 23.709ZM70.2128 50.7004C72.0762 48.837 75.0974 48.837 76.9608 50.7004C78.8242 52.5638 78.8242 55.585 76.9608 57.4484L56.7168 77.6925C54.8534 79.5559 51.8322 79.5559 49.9688 77.6925C48.1054 75.829 48.1054 72.8079 49.9688 70.9445L70.2128 50.7004Z"
        fill="#fff"
    />
  </svg>
</template>

<script>
export default {
  name: 'Copy'
}
</script>