<template>
  <div class="successShared">
    <Ok />
    <span class="linkCopied">Link copied</span>
  </div>
</template>

<script>
import Ok from './Ok.vue'
export default {
  name: 'SuccessShared',
  components: {Ok}
}
</script>

<style>
.successShared {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #3E7548;
  border-radius: 8px;
  padding: 8px 6px;
  bottom: 130%;
  right: 0;
}

.linkCopied {
  margin-left: 8px;
  color: #fff;
}
.copySvg {
  height: 20px;
  width: 20px;
}
</style>