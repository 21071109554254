<template>
  <div class="wrapper">
    <div class="content relative p-24" v-if="!$store.getters['getLoading']">
      <p
        class="absolute top-0 left-1/2 text-white transform -translate-x-1/2 flex justify-center items-center space-x-2 py-2 px-2 rounded-md mt-20"
      >
        <span>
          <i class="text-white text-xl icon-info-outline transform translate-y-1 inline-block"></i>
        </span>
        <span>
          {{ $t('preview.page.warning') }}
        </span>
      </p>

      <div class="flexCenter">
        <div class="copy">
          <SuccessShared v-if="showSuccess" />
          <div>
            <Copy />
          </div>

          <span class="underline" style="margin-right: 5px" v-on:click="copyShareLink()">{{ $t('copy.link') }} </span
          ><span class="noUnderline">{{ $t('to.share') }}</span>
        </div>
        <flipbook
          class="flipbook"
          :pages="pages"
          v-slot="flipbook"
          :loading-image="setErrorImage"
          :flip-duration="flipDuration"
          @flip-left-end="onLeftFlipEnd"
          @flip-right-end="onRightFlipEnd"
        >
          <span
            class="flex justify-center items-center space-x-2 absolute top-0 -mt-12 left-1/2 transform -translate-x-1/2 z-10"
          >
            <span class="inline-block relative">
              <input
                id="go-to-page"
                type="text"
                class="go-to-input"
                v-model="pageNumber"
                :disabled="isPageFlipping"
                @change="() => onGoToPage(flipbook)"
                @focus="showPagesDropdown = true"
                @blur="onInputBlur"
                @keyup.enter="() => onGoToPage(flipbook)"
              />

              <ul
                v-if="showPagesDropdown"
                id="pages-dropdown"
                class="absolute top-full left-0 bg-white min-w-full rounded-b-md overflow-y-auto z-10 shadow-md custom-scrollbar"
                style="max-height: 300px"
              >
                <li v-for="page in getPages" :key="page">
                  <button
                    class="px-2 py-1 border-b text-center inline-block w-full h-full hover:bg-gray-100"
                    @click="
                      () => {
                        pageNumber = page
                        onGoToPage(flipbook)
                      }
                    "
                  >
                    {{ page }}
                  </button>
                </li>
              </ul>
            </span>

            <span class="text-white"> of {{ flipbook.numPages }} </span>
          </span>

          <div class="flip-left">
            <i @click="flipbook.flipLeft" class="icon-chevron-left"></i>
          </div>
          <div class="flip-right">
            <i @click="flipbook.flipLeft" class="icon-chevron-left"></i>
          </div>
        </flipbook>
      </div>
    </div>
    <div v-else class="center-loading">
      <img alt="loading" src="/loading.gif" style="max-height: 100px" />
    </div>
  </div>
</template>

<script>
import Flipbook from 'flipbook-vue'
import Copy from '../components/Copy'
import SuccessShared from '../components/SuccessShared'
import FlipNext from '../components/FlipNext'
import FlipPrevious from '../components/FlipPrevious'

export default {
  name: 'Preview',
  components: {
    Flipbook,
    Copy,
    SuccessShared,
    FlipNext,
    FlipPrevious,
  },
  data() {
    return {
      pageNumber: 1,
      shareLink: process.env.VUE_APP_HEADLESS_PREVIEW,
      showSuccess: false,
      showNext: true,
      showPrevious: false,
      isLoading: false,
      zoom: 0,
      pageFlip: null,
      pages: [],
      isLinda: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      imageId: 0,
      flipDuration: 1000,
      isPageFlipping: false,
      showPagesDropdown: false,
    }
  },
  computed: {
    setErrorImage() {
      if (this.isLinda) {
        return `${this.baseUrl}/linda_blank.png?id=${this.imageId}`
      }
      return `${this.baseUrl}/blank.png?id=${this.imageId}`
    },

    getPages() {
      // The array from pages.length - 1. It should have first page as 1 and last page as pages.length. All pages in between should be even numbers.
      const pages = this.pages.filter((page) => page !== null)

      if (pages.length === 0) return []

      const array = Array.from({ length: pages.length - 1 }, (_, i) => i + 1)
      const evenArray = array.filter((page) => page % 2 === 0)
      evenArray.unshift(1)
      evenArray.push(pages.length)

      return evenArray
    },
  },
  methods: {
    async onGoToPage(flipb) {
      this.isPageFlipping = true
      this.showPagesDropdown = false

      try {
        // If this.pageNumber is not a number, parseInt will return NaN. Then this.pageNumber will be set to 1.
        if (isNaN(parseInt(this.pageNumber))) {
          this.pageNumber = 1
        }

        this.pageNumber = parseInt(this.pageNumber)

        if (this.pageNumber < 1) {
          this.pageNumber = 1
        } else if (this.pageNumber > flipb.numPages) {
          this.pageNumber = flipb.numPages
        }
      } catch (e) {
        this.isPageFlipping = false
        return
      }

      if (this.pageNumber > flipb.page && this.pageNumber <= flipb.numPages) {
        // flipb.flipRight() until this.pageNumber
        this.pageNumber = this.pageNumber % 2 === 0 ? this.pageNumber : this.pageNumber - 1

        const numOfFlip = (this.pageNumber - flipb.page) / 2

        for (let i = 0; i < numOfFlip; i++) {
          this.flipDuration = 500
          await this.awaitThePageflip(flipb, 'right', i === 0 ? 0 : 550)
        }

        this.flipDuration = 1000

        this.isPageFlipping = false
      } else if (this.pageNumber < flipb.page && this.pageNumber >= 1) {
        // flipb.flipLeft() until this.pageNumber
        this.pageNumber = this.pageNumber % 2 === 0 ? this.pageNumber : this.pageNumber !== 1 ? this.pageNumber - 1 : 1

        const numOfFlip = (flipb.page - this.pageNumber) / 2

        for (let i = 0; i < numOfFlip; i++) {
          this.flipDuration = 500
          await this.awaitThePageflip(flipb, 'left', i === 0 ? 0 : 550)
        }

        this.flipDuration = 1000

        this.isPageFlipping = false
      } else {
        this.isPageFlipping = false
        return
      }
    },

    awaitThePageflip(flipb, direction, interval) {
      return new Promise((resolve) => {
        setTimeout(() => {
          if (flipb) {
            if (direction === 'left') {
              flipb.canFlipLeft && flipb.flipLeft()
            } else {
              flipb.canFlipRight && flipb.flipRight()
            }
            resolve(true)
          }
        }, interval)
      })
    },

    async createThumbnailUrl(creationId, canvasId, type = 'png_fullsize', linda = false) {
      let that = this
      const randomInt = Math.floor(Math.random() * 1000)

      setInterval(function () {
        that.imageId = Math.floor(Math.random() * 1000)
      }, 1000)

      return `https://s3.eu-west-1.amazonaws.com/php.jilster.nl/${process.env.VUE_APP_STATUS}_magazinepages/${creationId}/${type}/${canvasId}.png?id=${randomInt}`
    },
    copyShareLink() {
      const el = document.createElement('textarea')
      el.value = this.shareLink + this.$route.params.magazineId
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)

      this.showSuccess = true
      setTimeout(() => {
        this.showSuccess = false
      }, 2000)
    },
    async getDocumentImages() {
      const documents = await this.$store.getters['getCreation'].data.pages
      this.pages.push(null)
      for (let i = 0; documents.length > i; i++) {
        await this.createThumbnailUrl(documents[i].creation_id, documents[i].id, 'png_fullsize', this.isLinda).then(
          (response) => {
            this.pages.push(response)
          }
        )
      }
    },

    onLeftFlipEnd(page) {
      if (this.isPageFlipping) {
        return
      }
      // Page number can be even number or 1
      if (page === 1) {
        this.pageNumber = 1
      } else {
        this.pageNumber = page % 2 === 0 ? page : page - 1
      }
    },

    onRightFlipEnd(page) {
      if (this.isPageFlipping) {
        return
      }

      // Page number can be even number or 1
      if (page === 1) {
        this.pageNumber = 1
      } else {
        this.pageNumber = page % 2 === 0 ? page : page - 1
      }
    },

    onInputBlur(e) {
      const that = this
      const input = e.target

      document.addEventListener('click', (_e) => {
        if (input !== _e.target) {
          that.closeDropdown(_e, that)
        }
      })
    },

    closeDropdown(e, that) {
      const dropdown = document.getElementById('pages-dropdown')

      if (dropdown && !dropdown.contains(e.target)) {
        that.showPagesDropdown = false
      }

      document.removeEventListener('click', that.closeDropdown)
    },
  },

  async created() {
    this.isLoading = true

    await this.$store.dispatch('getCreation', this.$route.params.magazineId)
    if (this.$store.getters['getCreation'].data.canvasVersion === 'linda') {
      this.isLinda = true
    }
    await this.getDocumentImages()
    await this.$store.dispatch('setLoading')
    this.isLoading = false
  },
}
</script>

<style>
.flip-left i {
  position: absolute;
  font-size: 40px;
  top: 40%;
  color: #999187;
}

.flipbook {
  margin-top: 10px;
  position: relative;
  width: 100%;
  height: 90vh;
}

.flip-right i {
  position: absolute;
  font-size: 40px;
  top: 40%;
  right: 0;
  transform: scaleX(-1);
  color: #999187;
}

.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: unset;
}

.flexCenter {
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
}

.copy {
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: right;
  align-items: center;
  margin: 0 auto;
  overflow: unset;
  position: relative;
}

.copy svg {
  height: 20px;
  width: 20px;
  margin-right: 4px;
}

.copy .underline {
  color: #fff;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.copy .noUnderline {
  color: #fff;
  font-weight: normal;
  text-decoration: none;
}

.flipbookWrapper {
  flex: 1;
  max-width: 100%;
  padding: 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#page-flip-container {
  max-width: 800px;
  max-height: 600px;
  overflow: auto;
}

#page-flip-container::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
#page-flip-container::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
#page-flip-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 999px;
}

/* Handle on hover */
#page-flip-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.btnFlip {
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.hiddenBtnFlip {
  visibility: hidden;
}

.zoomActions {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.zoomButtons {
  padding: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
}

.zoomButtons i {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  font-weight: normal !important;
}

.zoomActions p {
  margin: 0 16px;
  color: black !important;
}

.go-to-input {
  width: 60px;
  height: 30px;
  border: 1px solid #999187;
  border-radius: 4px;
  padding: 0 8px;
  font-size: 18px;
  text-align: center;
  outline: none;
  position: relative;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #999187 transparent;
}
</style>
