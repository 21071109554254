<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.0156 29C16.2344 29 16.4688 28.9062 16.7188 28.7188L24.6875 20.7031C25.1562 20.2344 25.1562 19.7656 24.6875 19.2969L16.7188 11.2812C16.2188 10.8438 15.75 10.8438 15.3125 11.2812C14.8125 11.7812 14.8125 12.25 15.3125 12.6875L22.5781 20L15.3125 27.3125C14.8125 27.75 14.8125 28.2188 15.3125 28.7188C15.5 28.9062 15.7344 29 16.0156 29Z" fill="#999187"/>
  </svg>
</template>

<script>
export default {
  name: 'FlipNext'
}
</script>