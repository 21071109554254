<template>
  <div id="topbar"
       :class="[
      {
        'topbar-waring': topbar.content === 'warning',
        'topbar-info':  topbar.content === 'info',
        'topbar-offers':  topbar.content === 'offer'
      },
    ]">
    <span>{{checkLanguage}}</span>
    <Close @click.native="setTopbarInactive" color="#fff"/>
  </div>
</template>

<script>
import Close from "./Close";
export default {
  components: {Close},
  props: {
    topbar: Object,
  },
  computed:{
    checkLanguage(){
      const userLang = navigator.language || navigator.userLanguage;

      let topbarText = "";

      switch(userLang) {
        case 'nl-NL':
          topbarText = this.topbar.content_nl
          break;
        case 'de-DE':
          topbarText = this.topbar.content_de
          break;
        default:
          topbarText = this.topbar.content_en
      }

      return topbarText;
    },
  },
  methods: {
    setTopbarInactive(){
      let settings = this.$store.getters['getSettings'];
      const topbar = settings.filter(item => item.name === 'SYSTEM_URGENT_MESSAGE');
      topbar[0].active = false;

      const index = settings.findIndex(item => item.name === 'SYSTEM_URGENT_MESSAGE');
      settings.splice(index, 1);

      settings.push(topbar[0]);
      this.$store.commit('SET_SETTINGS', settings);
    }
  },
  name: "Topbar",
}
</script>
