<template>
  <div class="not-found">
    <div class="jilsterImg">
      <img src="/error.png" />
    </div>
<!--    <div class="headerText">-->
<!--      <h1>Oops. This is awkward</h1>-->
<!--    </div>-->
    <div class="info">
      <p>{{$t('page.not.found.text')}}</p>
    </div>
    <div class="actions">
      <a href="https://makerspace.jilster.app/login"><button>{{$t('page.not.found.button.text')}}</button></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound"
}
</script>

<style>
.not-found {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.jilsterImg {
  width: 50%;
  margin-bottom: 56px;
}

.jilsterImg img {
  max-width: 100%;
}

.headerText {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerText h1 {
  margin-bottom: 16px;
}

.info {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 32px;
}

h1, p {
  color: white;
}

.actions button {
  padding: 12px;
  background-color: #2A65C4;
  color: white;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
}
</style>
