<template>
  <Maintenance v-if="maintenanceModeCheck"/>
    <div id="app" v-else>
      <Navbar/>
    <router-view/>
  </div>
</template>

<script>
import Navbar from "./components/Navbar";
import axios from "axios";
import Maintenance from "@/components/Maintenance";
import systemSettingsSetup from "@/mixins/systemSettingsSetup";
export default {
  components: {Maintenance, Navbar},
  mixins: [systemSettingsSetup],
  computed:{
    maintenanceModeCheck(){
      const settings = this.$store.getters['getSettings'];
      if(settings.length !== 0){
        const maintenanceMode = settings.filter(item => item.name === 'SYSTEM_IN_MAINTENANCE');
        return maintenanceMode[0].active;
      }
      return false;
    }
  },
  async created() {
    const SYSTEM_ENDPOINT = "system";

    const settings = await axios(`${process.env.VUE_APP_API_URL}${SYSTEM_ENDPOINT}/settings/actual?token=${process.env.VUE_APP_SETTINGS_KEY}`);

    this.$store.commit('SET_SETTINGS', settings.data.data);

    await this.systemSettingsSetup();
  }
}
</script>

<style>
#app {
  height: 100%;
}
</style>
