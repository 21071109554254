import axios from "axios";
import router from "./../router";

const state = {
    creation: [],
    loading: true
};

const getters = {
    getCreation: (state) => {
        return state.creation;
    },
    getLoading: (state) =>{
        return state.loading;
    }
};

const actions = {
    async getCreation({ commit }, id) {
        try{
            const result = await axios.get(`${process.env.VUE_APP_API_URL}shared/${id}/pages`)
            commit('SET_CREATION', result.data);
        }catch (e) {
            await router.push("/error/404");
        }
    },
    setLoading({commit}){
        commit('SET_LOADING', false)
    }
};

const mutations = {
    SET_CREATION(state, creation) {
        state.creation = creation;
    },
    SET_LOADING(state, bool){
        state.loading = bool;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
