<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.9844 29C23.7031 29 23.4688 28.9062 23.2812 28.7188L15.3125 20.7031C15.0938 20.5156 14.9844 20.2812 14.9844 20C14.9844 19.7188 15.0938 19.4844 15.3125 19.2969L23.2812 11.2812C23.7812 10.8438 24.25 10.8438 24.6875 11.2812C25.1875 11.7812 25.1875 12.25 24.6875 12.6875L17.4219 20L24.6875 27.3125C25.1875 27.75 25.1875 28.2188 24.6875 28.7188C24.5 28.9062 24.2656 29 23.9844 29Z" fill="#999187"/>
  </svg>
</template>

<script>
export default {
  name: 'FlipPrevious'
}
</script>