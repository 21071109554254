import Vue from 'vue'
import Vuex from 'vuex'
import creations from "./creations";
import system from "./system"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    creations,
    system
  },
})
