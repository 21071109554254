<template>
  <div id="maintenance">
    <div class="jilster_maintenace_mask-container">
      <div class="jilster_maintenace_mask"></div>
    </div>
    <h1>{{$t('maintenance.text')}}</h1>
    <p>{{$t('maintenance.body.text')}}</p>
    <!--    <Button @click.native="goToWebsite" buttonType="primary" buttonText="wetwetwertwert"/>-->
    <button @click="goToWebsite">{{$t('maintenance.button')}}</button>
  </div>
</template>

<script>

export default {
  name: "Maintenance",
  methods: {
    goToWebsite() {
      window.open('https://jilster.com', '_blank');
    }
  }
}
</script>

<style>
#maintenance {
  text-align: center;
  background: #000;
  height: 100vh;
  padding-top: 190px !important;
}

#maintenance .jilster_maintenace_mask-container {
  height: 186px;
  width: 672px;
  margin: 0 auto 56px;
}

#maintenance .jilster_maintenace_mask-container .jilster_maintenace_mask {
  width: 100%;
  height: 100%;
  margin-bottom: 55px;
  background-image: url(/rage.gif);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-mask-image: url(/jilster-logo-transparent.svg);
  mask-image: url(/jilster-logo-transparent.svg);
}

#maintenance h1 {
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  color: #fff;
}

#maintenance p {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 55px;
  color: #fff;
}

#maintenance button {
  margin: 0 auto;
  text-decoration: none;
  background: #2a65c4;
  color: #fff;
  border: none !important;
  border-radius: 4px;
  padding: 10px 12px;
  width: 160px;
  text-align: center;
  cursor: pointer;
}

</style>
