import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { FlippingWidgets } from 'vue-turnjs'
import 'vue-turnjs/dist/vue-turnjs.esm.css'

import './main.css'
import './index.css'

import Pusher from 'pusher-js'
import i18n from '@/i18n'

Vue.use(FlippingWidgets)
Vue.config.productionTip = false

Pusher.logToConsole = process.env.VUE_APP_PUSHER_DEBUG === 'true'

let pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
  cluster: 'eu',
  authEndpoint: process.env.VUE_APP_PUSHER_AUTH_ENDPOINT,
})

Vue.prototype.$pusher = pusher

const handleWheel = function (e) {
  if (e.ctrlKey || e.metaKey) e.preventDefault()
}
window.addEventListener('wheel', handleWheel, { passive: false })

window.addEventListener(
  'keydown',
  function (e) {
    if (
      (e.ctrlKey || e.metaKey) &&
      (e.which === 61 || e.which === 107 || e.which === 173 || e.which === 109 || e.which === 187 || e.which === 189)
    ) {
      e.preventDefault()
    }
  },
  false
)

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
