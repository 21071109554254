<template>
  <div>
    <svg
        width="138"
        height="38"
        viewBox="0 0 138 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
      <path
          d="M26.513 14.152H38.3077V36.9811H26.513V14.152ZM128.982 13.1322L128.494 10.8107H117.675V17.2246C115.123 12.4947 109.81 10.1199 104.467 10.0843C99.1346 10.0843 94.527 12.1063 91.8924 15.8529V10.7168H87.3608V4.56879L75.664 5.29515V10.7158H72.7885V12.2546C70.4357 10.6368 66.9956 9.93904 63.0187 9.93904C58.2002 9.93904 53.7825 11.4037 51.6647 14.3882V3.16643H39.8669V3.17632C38.6156 1.1563 36.1149 0 32.4198 0C28.7168 0 26.2131 1.13358 24.9448 3.11802H4.48463V12.7942H11.6978V21.3081C11.6978 27.9364 4.04586 26.3868 0 25.8561V36.6905C12.6723 39.7907 24.9558 37.2736 24.9558 21.7933V10.446C26.2181 12.3465 28.6998 13.3525 32.4208 13.3525C36.1739 13.3525 38.6366 12.3287 39.8679 10.3956V36.9821H51.6647V33.3324C53.8585 36.6026 58.4501 38 63.6044 38C69.1874 38 74.7025 36.2162 76.3016 31.8658C78.4145 38.0385 85.4598 38.8726 92.2352 36.8882L91.9623 31.937C94.473 35.5392 98.7728 37.662 104.028 37.662C110.176 37.662 115.053 35.5095 117.674 30.4545V36.9821H129.372V26.7752C129.372 21.743 133.32 19.7615 137.756 21.1164L138 10.6674C134.977 9.74732 130.591 9.98946 128.982 13.1322ZM61.7983 28.7587H51.6617V23.339C54.0504 26.8207 59.2077 27.4789 63.0156 27.8386C63.9432 27.889 65.1126 27.9849 65.1126 29.1451C65.1136 30.4516 61.9442 30.7886 61.7983 28.7587ZM75.662 24.773C73.4451 21.1658 68.2759 20.6262 63.8952 20.1479C62.9207 20.051 61.9462 19.7605 61.9462 18.6002C61.9462 17.2938 64.7238 17.1495 64.7727 18.6487L75.663 18.6516V24.773H75.662ZM87.3598 27.6449V18.6506H90.4232C89.8605 20.1736 89.5506 21.8853 89.5506 23.7768C89.5506 26.1062 90.0184 28.2161 90.8709 30.0464C89.2328 30.2431 87.3598 29.6076 87.3598 27.6449ZM108.025 21.5997H100.861C101.493 18.116 107.392 18.0666 108.025 21.5997ZM117.675 28.1292H107.877C105.878 30.4536 101.396 30.0148 100.86 26.7762H117.674V28.1292H117.675Z"
          fill="#F9F8F8"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "JilsterLogo"
}
</script>
