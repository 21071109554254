<template>
  <div>
    <Topbar :topbar="topbar" v-if="topbar.active"/>
    <div class="nav">
      <a class="ml-4 block" href="" target="_blank">
        <JilsterLogo/>
      </a>
      <a :href="makerspaceUrl" class="cta-button" target="_blank">{{$t('create.your.own')}}</a>
    </div>
  </div>

</template>

<script>
import JilsterLogo from "../vectors/JilsterLogo";
import Topbar from "@/components/Topbar";
export default {
  name: "Navbar",
  components: {Topbar, JilsterLogo},
  data() {
    return {
      makerspaceUrl: process.env.VUE_APP_MAKERSPACE_URL,
    }
  },
  computed:{
    topbar(){
      const settings = this.$store.getters['getSettings'];
      if(settings.length !== 0){
        const topbar = settings.filter(item => item.name === 'SYSTEM_URGENT_MESSAGE');
        this.$store.commit('SET_TOPBARACTIVE', topbar[0].active)
        return topbar[0];
      }
      this.$store.commit('SET_TOPBARACTIVE', false);
      return false;
    }
  },
}
</script>

<style>
.nav {
  padding: 8px 62px 8px 24px;
  background: black;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cta-button {
  text-decoration: none;
  background: #2a65c4;
  color: #fff;
  border: none !important;
  border-radius: 4px;
  padding: 10px 12px;
  width: 160px;
  text-align: center;
}
</style>
